<template>
  <d-remote-monitoring-indicator-trends-view :id="id" />
</template>

<script>

export default {
  name: 'RemoteMonitoringIndicatorTrendsView',
  page: {
    title: 'CRM Doctis - Дис. мониторинг',
  },

  data() {
    return {
      id: null,
    };
  },

  mounted() {
    this.id = this.$attrs.id;
  },

};
</script>
